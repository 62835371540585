import { Select, Spin } from "antd";
import { BaseEntity } from "models/common";
import {
    forwardRef,
    ReactNode,
    Ref,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";

/**
 * Defines the props a higher-order entity selector need to implement, and the
 * type of entity being selected
 */
export interface EntitySelectorProps<TEntity extends BaseEntity> {
    /**
     * Callback for when an entity has been selected
     */
    onSelected: (entity: TEntity | undefined) => void;
    /**
     * Callback for when the selection has been cleared
     */
    onClear?: () => void;
    /**
     * The initial entity the selector should start off with
     */
    initialEntity?: TEntity;
    /**
     * Whether or not the selector should take focus
     */
    autoFocus?: boolean;
    /**
     * Whether or not the selector is disabled
     */
    disabled?: boolean;
    /**
     * A reference to the selector
     */
    ref?: Ref<EntitySelectorRef>;
    /**
     * Callback for when the escape key is pressed
     */
    onCancel?: () => void;

    onBlur?: () => void;

    placeholder?: string;
}

export interface EntitySelectorRef {
    reset: () => void;
}

interface ExtendedEntitySelectProps<TEntity extends BaseEntity>
    extends EntitySelectorProps<TEntity> {
    search: (term: string) => Promise<TEntity[]>;
    resultDisplay: (entity: TEntity) => ReactNode;
}

const { Option } = Select;

const EntitySelector = <TEntity extends BaseEntity>(
    props: ExtendedEntitySelectProps<TEntity>,
    ref?: Ref<EntitySelectorRef>
) => {
    const [searchResults, setSearchResults] = useState<TEntity[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedEntity, setSelectedEntity] = useState<TEntity | undefined>();

    let searchTimeout: ReturnType<typeof setTimeout>;

    useMemo(() => {
        if (props.initialEntity) {
            setSearchResults([props.initialEntity]);
            setSelectedEntity(props.initialEntity);
        }
    }, [props.initialEntity]);

    useImperativeHandle(ref, () => ({
        reset,
    }));

    const onEntitySelected = (id: string) => {
        if (!id) {
            return;
        }
        const entity = searchResults.find((r) => r.id === id);

        setSelectedEntity(entity);
        props.onSelected(entity);
    };

    const onEntitySearch = (term: string) => {
        clearTimeout(searchTimeout);
        if (!term) {
            setSearchResults([]);
            return;
        }
        searchTimeout = setTimeout(async () => {
            setIsLoading(true);
            const results = await props.search(term);
            setSearchResults(results);
            setIsLoading(false);
        }, 500);
    };

    const reset = () => {
        setSelectedEntity(undefined);
        setSearchResults([]);
        if (props.onClear) {
            props.onClear();
        }
    };

    const focusSelect = (select: any) => {
        if (props.autoFocus && select) {
            select.focus();
        }
    };

    return (
        <div className="selector-wrapper">
            <Select
                ref={focusSelect}
                disabled={props.disabled || false}
                value={selectedEntity?.id}
                placeholder={props.placeholder || "Search here"}
                notFoundContent={isLoading ? <Spin size="small" /> : null}
                allowClear
                showSearch
                showArrow={false}
                filterOption={false}
                dropdownStyle={{ zIndex: 9999 }}
                onSearch={onEntitySearch}
                onChange={onEntitySelected}
                onClear={reset}
                onBlur={props.onBlur}
                onKeyDown={(e) => {
                    if (e.key === "Escape" && props.onCancel) {
                        props.onCancel();
                    }
                }}>
                {searchResults.map((r) => (
                    <Option key={r.id} value={r.id}>
                        {props.resultDisplay(r)}
                    </Option>
                ))}
            </Select>
        </div>
    );
};

export default forwardRef<EntitySelectorRef, ExtendedEntitySelectProps<any>>(
    EntitySelector
);
