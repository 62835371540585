import { Form, FormInstance, Input } from "antd";
import Table, { ColumnGroupType, ColumnsType, ColumnType, TableProps } from "antd/lib/table";
import { cloneElement, createContext, FC, ReactElement, ReactNode, useContext, useEffect, useRef, useState } from "react";

const EditableContext = createContext<FormInstance<any> | null>(null);

type CellUpdate<T> = string | number | boolean | null | Partial<T>;
type EditModeRender<T> = (row: T, save: SaveEdit<T>, cancel: CancelEdit) => ReactElement;

export type SaveEdit<T> = (value?: CellUpdate<T>) => Promise<void>;
export type CancelEdit = () => void;
export type RowUpdate<T> = (record: T, partial: Partial<T>, dataIndex: keyof T) => Promise<void>;

export type EditableColumnsType<T> = (ColumnGroupType<T> | ColumnType<T> | EditableColumnType<T>)[];

export interface EditableColumnType<T> extends ColumnType<T> {
    editable?: boolean;
    editRender?: EditModeRender<T>;
    valuePropName?: string;
    handleDefaultEvents?: boolean;
}

interface EditableRowProps {
    index: number;
}

interface EditableCellProps<T> {
    title: ReactNode;
    editable: boolean;
    editRender: EditModeRender<T>;
    children: ReactNode;
    dataIndex: keyof T;
    record: T;
    handleSave: RowUpdate<T>;
    valuePropName: string;
    handleDefaultEvents: boolean;
}

type TableWithInlineEditingProps<RecordType extends object> = Omit<TableProps<RecordType>, 'columns'> & {
    columns: EditableColumnsType<RecordType>,
    onRowUpdate: RowUpdate<RecordType>
};

const EditableRow: FC<EditableRowProps> = ({ index, ...restProps }) => {
    const [form] = Form.useForm();

    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...restProps} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = <T extends object>({
    title,
    editable,
    editRender,
    children,
    dataIndex,
    record,
    handleSave,
    valuePropName,
    handleDefaultEvents,
    ...restProps
}: EditableCellProps<T>) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef<any>(null);
    const form = useContext(EditableContext)!

    useEffect(() => {
        if (editing && inputRef.current?.focus) {
            inputRef.current!.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
        form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    };

    const save = async (value?: CellUpdate<T>) => {
        try {
            const isPartial = value !== null && typeof value === 'object';

            if (isPartial) {
                form.setFieldsValue(value);
            } else if (value !== undefined) {
                form.setFieldValue(dataIndex as string, value);
            }

            // when value is undefined, the underlying value
            // of the control rendered by editRender is used & is
            // already in the form values
            const values: Partial<T> = {
                ...await form.validateFields(),
                ...(isPartial ? value : {})
            };

            toggleEdit();
            handleSave({ ...record, ...values }, values, dataIndex);
        } catch (errInfo) {
            console.error('Save failed:', errInfo);
        }
    };

    const cancelOnEscape = (e: any) => {
        if (e.key === "Escape")
            cancel();
    }

    const cancel = () => {
        setEditing(false);
    }

    let childNode = children;

    if (editable) {
        const events = handleDefaultEvents
            ? { onBlur: () => save(), onKeyDown: cancelOnEscape }
            : { /*   do nothing for blur or keydown events   */ };

        const node = cloneElement(editRender(record, save, cancel), {
            ref: inputRef,
            ...events
        });

        childNode = editing ? (
            <Form.Item
                style={{ margin: 0 }}
                name={dataIndex.toString()}
                valuePropName={valuePropName}
                rules={[
                    {
                        required: true,
                        message: `${title} is required.`,
                    },
                ]}
            >
                {node}
            </Form.Item>
        ) : (
            <div className="editable-cell-value-wrap" style={{ paddingRight: 24, cursor: 'pointer' }} onClick={toggleEdit}>
                {children}
            </div>
        );
    }

    return <td {...restProps}>{childNode}</td>;
};

const asEditableColumns = <T extends object>(columns: EditableColumnType<T>[], handleSave: RowUpdate<T>) => {
    return columns.map(col => {
        const { title, editable, dataIndex } = col;

        if (!editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record: T) => ({
                title,
                editable,
                dataIndex,
                record,
                editRender: col.editRender ?? ((_, s, __) => <Input onPressEnter={() => s()} />),
                valuePropName: col.valuePropName ?? "value",
                handleSave,
                handleDefaultEvents: col.handleDefaultEvents ?? true
            } as EditableCellProps<T>)
        }
    }) as ColumnsType<T>;
};

export const EditableTable = <T extends object>({
    columns,
    onRowUpdate,
    ...restProps
}: TableWithInlineEditingProps<T>) => {
    const editableColumns = asEditableColumns(columns, onRowUpdate);

    return <Table
        {...restProps}
        columns={editableColumns}
        components={{
            body: {
                row: EditableRow,
                cell: EditableCell
            }
        }}
    />;
};