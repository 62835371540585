import { Card, Col, Row, Space } from "antd";
import PackageSelector from "components/PackageSelector";
import { StaffPackage } from "models/packages";
import { FC, MouseEvent, useState } from "react";
import { localizedDate } from "util/helpers";
import BulkActionModal, { BulkActionModalProps } from "./BulkActionModal";

const ChangePackageIdModal: FC<BulkActionModalProps & {
    onConfirm: (newPackageId: string) => Promise<void>
}> = ({ affectedCount, open, onCancel, onConfirm }) => {
    const [newPackage, setNewPackage] = useState<StaffPackage | undefined>();

    const reset = () => setNewPackage(undefined);

    const confirm = async () => {
        await onConfirm(newPackage!.id);
        reset();
    }

    const cancel = (e: MouseEvent<HTMLElement>) => {
        if (onCancel) {
            onCancel(e);
        }
        reset();
    }

    return <BulkActionModal
        title="Change Package ID"
        affectedCount={affectedCount}
        open={open}
        onOk={confirm}
        onCancel={cancel}
        allowConfirm={!!newPackage}
    >
        <Row>
            <Col span={24}>
                <Space>
                    <div>Select New Package:</div>
                    <PackageSelector
                        autoFocus
                        placeholder="New Package ID"
                        onSelected={setNewPackage}
                        onClear={reset}
                    />
                </Space>
            </Col>

            <Col span={24}>
                {newPackage && <Card
                    title={`Package #${newPackage.friendlyId}`}
                    style={{ marginTop: '20px', backgroundColor: 'rgba(from var(--dcs-warning) r g b / 0.05)' }}
                    bodyStyle={{ padding: '10px 24px' }}>
                    <p>{newPackage.customerFirstName} {newPackage.customerLastName} - {newPackage.customerEmail}</p>
                    <p>Received on {localizedDate(newPackage.receivedDate)}</p>
                    <p>Type: {newPackage.packageTypeInternalName}</p>
                </Card>}
            </Col>
        </Row>
    </BulkActionModal>
};

export default ChangePackageIdModal;