import { FC } from "react";
import BulkActionModal, { BulkActionModalProps } from "./BulkActionModal";

const CardsReadyForUploadModal: FC<BulkActionModalProps & {
    onConfirm: () => Promise<void>
}> = ({ affectedCount, open, onCancel, onConfirm }) => {
    const confirm = async () => {
        await onConfirm();
    }

    return <BulkActionModal
        title="Mark Cards Ready to Upload"
        affectedCount={affectedCount}
        open={open}
        onOk={confirm}
        onCancel={onCancel}
    >
        <div>All selected cards have passed QA. The 'Do Not Upload' flag on any cards within this selection will be respected.</div>
    </BulkActionModal>
};

export default CardsReadyForUploadModal;