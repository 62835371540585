import usePackageByFriendlyId from "hooks/usePackageByFriendlyId";
import { StaffPackage } from "models/packages";
import { forwardRef, Ref } from "react";
import EntitySelector, { EntitySelectorProps, EntitySelectorRef } from "./EntitySelector";
import MultiValueDisplay from "./MultiValueDisplay";

const resultDisplay = ({ friendlyId, customerFirstName, customerLastName }: StaffPackage) => {
    return <MultiValueDisplay
        title={friendlyId.toString()}
        subTitle={`${customerFirstName} ${customerLastName}`}
    />;
};

const PackageSelector = (
    props: EntitySelectorProps<StaffPackage>,
    ref: Ref<EntitySelectorRef>
) => {
    const { findPackage } = usePackageByFriendlyId();

    const search = async (str: string) => {
        try {
            const id = Number(str);
            if (isNaN(id)) {
                return [];
            }

            const pkg = await findPackage(id);

            return [pkg];
        } catch {
            return [];
        }
    }

    return <EntitySelector
        ref={ref}
        {...props}
        search={search}
        resultDisplay={resultDisplay}
    />
};

export default forwardRef<
    EntitySelectorRef,
    EntitySelectorProps<StaffPackage>
>(PackageSelector);