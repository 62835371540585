import { LoadingOutlined, StopOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Image, Skeleton } from "antd";
import { FilterValue } from "antd/lib/table/interface";
import PackageSelector from "components/PackageSelector";
import CardQAToolbar from "components/staff/CardTitleQA/CardQAToolBar";
import GradeOrConditionSelector from "components/staff/CardTitleQA/GradeOrConditionSelector";
import {
    CardTitleInQA,
    defaultLotCondition,
    defaultUngradedCondition,
    GameCategory
} from "models/cardIngestion";
import { InitialPageRequest, PageRequest } from "models/common";
import useAssignedCardTitle from "pages/typer/useAssignCardTitle";
import { HTMLAttributes, Key, Suspense, useCallback, useState } from "react";
import { EditableColumnsType, EditableTable } from "util/editableTable";
import { useBatchSearching, useBulkActions, useCardTitlePatching, useCardTitlesToQA, useReadyForUpload } from "./useCardTitlesToQA";

const CardTitleQADashboard = () => {
    const [{ page, pageSize, withinDays, sort }, setPageRequest] =
        useState<PageRequest>({ ...InitialPageRequest, pageSize: 30 });
    const { isLoading, data: resp, refetch } = useCardTitlesToQA({ page, pageSize, withinDays, sort });
    const { data, totalRecords } = resp || {};
    const { patchCardTitle, isPatching } = useCardTitlePatching();
    const { doNotUpload, allowUpload, isSaving } = useAssignedCardTitle();
    const { findBatch } = useBatchSearching();
    const { bulkPackageIdUpdate, bulkDoNotUploadUpdate, isBulkUpdating } = useBulkActions();
    const { batchesPassedQA, cardsPassedQA, isCompleting } = useReadyForUpload();
    const [selectedCardIds, setSelectedCardIds] = useState<Key[]>([]);
    const [foundCardId, setFoundCardId] = useState<string | null>(null);
    const [searchFailed, setSearchFailed] = useState(false);
    const working = isLoading || isPatching || isSaving || isBulkUpdating || isCompleting;

    const columns: EditableColumnsType<CardTitleInQA> = [
        {
            title: "",
            key: "doNotUpload",
            render: (_, rec) => rec.doNotUpload ? (
                <Button type="primary" size="small" icon={<UploadOutlined />} style={{ marginLeft: '10px', backgroundColor: 'var(--dcs-dark-green)' }} onClick={() => toggleDoNotUpload(rec.id, false)} />
            ) : (
                <Button type="primary" size="small" icon={<StopOutlined />} danger style={{ marginLeft: '10px' }} onClick={() => toggleDoNotUpload(rec.id, true)} />
            )
        },
        {
            title: "Photos",
            key: "photos",
            render: (_, rec) => <Image.PreviewGroup>
                <Image
                    src={rec.photos[0].imageUrl}
                    width={30}
                    placeholder={<LoadingOutlined />} />
                <Divider type="vertical" />
                <Image
                    src={rec.photos[1].imageUrl}
                    width={30}
                    placeholder={<LoadingOutlined />} />
            </Image.PreviewGroup>
        },
        {
            title: "Help / Notes",
            dataIndex: "notes",
            render: (_, rec) => `${rec.notes ?? ''} ${rec.doNotUploadReason ?? ''}`
        },
        {
            title: "Package ID",
            dataIndex: "packageFriendlyId",
            width: '200px',
            editable: true,
            editRender: (row, save, cancel) => <PackageSelector
                autoFocus
                placeholder={row.packageFriendlyId?.toString() ?? "Package ID"}
                onSelected={(pkg) => save({
                    packageId: pkg?.id,
                    packageFriendlyId: pkg?.friendlyId
                })}
                onClear={cancel}
                onCancel={cancel}
                onBlur={cancel}
            />
        },
        {
            title: "Card Title",
            dataIndex: "title",
            editable: true
        },
        {
            title: "Lot?",
            dataIndex: "isLot",
            editable: true,
            render: (isLot) => isLot ? "Yes" : "No",
            editRender: () => <Checkbox />,
            valuePropName: "checked"
        },
        {
            title: "Temp. SKU",
            dataIndex: "tempSKU"
        },
        {
            title: "Status / Grader",
            dataIndex: "statusName",
            render: (_, rec) => rec.gradingCompanyId === null
                ? rec.statusName
                : rec.gradingCompanyName,
            editable: true,
            handleDefaultEvents: false,
            editRender: (row, save, cancel) => <div tabIndex={0}
                onKeyDown={e => {
                    if (e.key === "Escape") {
                        cancel();
                    }
                }}
                onBlur={e => {
                    if (!e.currentTarget.contains(e.relatedTarget)) {
                        cancel();
                    }
                }}>
                <Suspense fallback={<Skeleton.Input active block size="large" />}>
                    <GradeOrConditionSelector
                        row={row}
                        save={save}
                        cancel={cancel}
                    />
                </Suspense>
            </div>
        },
        {
            title: "Grade / Cond.",
            key: "gradeOrCondition",
            render: (_, rec) => rec.gradeId === null
                ? rec.conditionName
                : rec.gradeName
        }
    ];

    const searchForBatch = async (term: string) => {
        setSearchFailed(false);

        try {
            const result = await findBatch({ term, pageSize: pageSize! })

            setFoundCardId(result.cardId);
            setPageRequest({
                page: result.pageNumber,
                pageSize,
                withinDays,
                sort
            });
        } catch (error) {
            setSearchFailed(true);
        }
    }

    const toggleDoNotUpload = async (cardId: string, doNotUploadState: boolean) => {
        if (doNotUploadState) {
            await doNotUpload({ cardId, reason: 'Do Not Upload' });
        } else {
            await allowUpload(cardId);
        }

        await refetch();
    }

    const tableChanged = (
        // pagination
        { current: newPage, pageSize: newPageSize }: any,
        // filtering
        filters: Record<string, FilterValue | null>,
        // sorting
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
        setFoundCardId(null);
    };

    const cascadingUpdates = useCallback((newRow: CardTitleInQA, dataIndex: string) => {
        const originalRow = data?.find(x => x.id === newRow.id)!;

        // isLot changed, default certain row properties accordingly
        if (dataIndex === 'isLot' && originalRow.isLot !== newRow.isLot) {
            // the default condition varies based on whether or not the category is 'Game'
            const isGame = newRow.category === GameCategory;

            return {
                ...(newRow.isLot
                    ? defaultLotCondition()
                    : defaultUngradedCondition(isGame))
            }
        }

        return {};
    }, [data]);

    const handleSave = useCallback(async (
        row: CardTitleInQA,
        partial: Partial<CardTitleInQA>,
        dataIndex: keyof CardTitleInQA
    ) => {
        const extraUpdates = cascadingUpdates(row, dataIndex);
        const payload = { id: row.id, ...partial, ...extraUpdates };

        await patchCardTitle(payload);
        await refetch();
    }, [refetch, patchCardTitle, cascadingUpdates]);

    const onBulkPackageIdUpdate = async (packageId: string) => {
        await bulkPackageIdUpdate({
            packageId,
            cardIds: selectedCardIds.map(c => c.toString())
        });
        await refetch();
        setSelectedCardIds([]);
    }

    const onBulkDoNotUpload = async () => {
        await bulkDoNotUploadUpdate(selectedCardIds.map(c => c.toString()));
        await refetch();
        setSelectedCardIds([]);
    }

    const onBatchesPassedQA = async (startBatch: string, endBatch: string) => {
        await batchesPassedQA({ startBatch, endBatch });
        await refetch();
    }

    const onCardsPassedQA = async () => {
        await cardsPassedQA(selectedCardIds.map(c => c.toString()));
        await refetch();
        setSelectedCardIds([]);
    }

    const highlightRow = (rec: CardTitleInQA): HTMLAttributes<never> => {
        if (rec.doNotUpload) {
            return { style: { backgroundColor: 'rgba(from var(--dcs-error-red) r g b / 0.2)' } };
        } else if (rec.id === foundCardId) {
            return { style: { backgroundColor: 'rgba(from var(--dcs-dark-green) r g b / 0.1)' } };
        }

        return {};
    }

    return <EditableTable
        size="small"
        title={() => <CardQAToolbar
            onSearch={async (term: string) => await searchForBatch(term)}
            onSearchClear={() => setSearchFailed(false)}
            searchFailed={searchFailed}
            selectedRowCount={selectedCardIds.length}
            onBulkDoNotUpload={onBulkDoNotUpload}
            onBulkPackageIdUpdate={onBulkPackageIdUpdate}
            onBatchesPassedQA={onBatchesPassedQA}
            onCardsPassedQA={onCardsPassedQA}
        />}
        loading={working}
        columns={columns}
        dataSource={data}
        rowKey={p => p.id}
        onRow={highlightRow}
        rowSelection={{
            selectedRowKeys: selectedCardIds,
            onChange: (keys) => setSelectedCardIds(keys),
            preserveSelectedRowKeys: true
        }}
        onRowUpdate={handleSave}
        pagination={{
            total: totalRecords,
            current: page,
            pageSize,
            pageSizeOptions: [30, 50, 100],
            showSizeChanger: true,
            showQuickJumper: true,
            position: ['topRight', 'bottomRight']
        }}
        onChange={tableChanged}
    />;
}

export default CardTitleQADashboard;