import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuthenticatedRequest, useAuthenticatedRequestCreator } from "hooks/useRequests";
import { CardTitleInQA, FoundInQA } from "models/cardIngestion";
import { PagedResult, PageRequest } from "models/common";
import { pageRequestToUrlSearchParams, toPatchDoc } from "util/helpers";

const useCardTitlesToQA = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    const getTitlesToQA = useAuthenticatedRequest<PagedResult<CardTitleInQA>>(({
        method: "get",
        url: `/card-titling/in-qa?${options}`
    }));

    return useQuery(["card-titling", "in-qa", pageRequest], getTitlesToQA);
}

const useCardTitlePatching = () => {
    const patch = useAuthenticatedRequestCreator<CardTitleInQA, Partial<CardTitleInQA>>(
        (partial: Partial<CardTitleInQA>) => {
            const { id, ...rec } = partial;
            const data = toPatchDoc(rec, {
                gradingCompanyId: 'graderId',
                gradingCompanyName: 'graderName'
            });

            return {
                method: "patch",
                url: `/card-titling/cards/${id!}/update`,
                data
            }
        }
    );

    const { mutateAsync: patchCardTitle, isLoading: isPatching } = useMutation(patch);

    return { patchCardTitle, isPatching };
}

const useBatchSearching = () => {
    const find = useAuthenticatedRequestCreator<FoundInQA, { term: string, pageSize: number }>(
        ({ term, pageSize }) => {
            return {
                method: 'get',
                url: `/card-titling/in-qa/find?term=${term}&pageSize=${pageSize}`
            }
        }, [404, 400]
    );

    return { findBatch: find };
}

const useBulkActions = () => {
    const packageIdUpdate = useAuthenticatedRequestCreator<void, { cardIds: string[], packageId: string }>(
        (data) => ({
            method: 'post',
            url: '/card-titling/in-qa/bulk/change-package-id',
            data
        }), [400, 404, 500]
    );

    const doNotUploadUpdate = useAuthenticatedRequestCreator<void, string[]>(
        (cardIds) => ({
            method: 'post',
            url: '/card-titling/in-qa/bulk/do-not-upload',
            data: { cardIds }
        }), [400, 500]
    );

    const { mutateAsync: bulkPackageIdUpdate, isLoading: isUpdatingPackageIds } = useMutation(packageIdUpdate);
    const { mutateAsync: bulkDoNotUploadUpdate, isLoading: isUpdatingDoNotUpload } = useMutation(doNotUploadUpdate);

    return {
        bulkPackageIdUpdate,
        bulkDoNotUploadUpdate,
        isBulkUpdating: isUpdatingPackageIds || isUpdatingDoNotUpload
    }
}

const useReadyForUpload = () => {
    const batchesReadyForUpload = useAuthenticatedRequestCreator<void, { startBatch: string, endBatch: string }>(
        (data) => ({
            method: 'post',
            url: '/card-titling/in-qa/batches-passed',
            data
        }), [400]
    );

    const cardsReadyForUpload = useAuthenticatedRequestCreator<void, string[]>(
        (cardIds) => ({
            method: 'post',
            url: '/card-titling/in-qa/cards-passed',
            data: { cardIds }
        }), [400]
    );

    const { mutateAsync: batchesPassedQA, isLoading: isCompletingBatches } = useMutation(batchesReadyForUpload);
    const { mutateAsync: cardsPassedQA, isLoading: isCompletingCards } = useMutation(cardsReadyForUpload);

    return {
        batchesPassedQA,
        cardsPassedQA,
        isCompleting: isCompletingBatches || isCompletingCards
    };
}

export {
    useBatchSearching,
    useBulkActions,
    useCardTitlePatching,
    useCardTitlesToQA,
    useReadyForUpload
};

