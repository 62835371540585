import { Alert, Col, Input, Modal, Row, Space } from "antd";
import { AxiosError } from "axios";
import { FC, useState } from "react";

interface ReadyForUploadModalProps {
    open: boolean;
    onCancel: () => void;
    onConfirm: (startBatch: string, endBatch: string) => Promise<void>
}

const BatchesReadyForUploadModal: FC<ReadyForUploadModalProps> = ({
    open, onCancel, onConfirm
}) => {
    const [startBatch, setStartBatch] = useState('');
    const [endBatch, setEndBatch] = useState('');
    const [working, setWorking] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const valid = startBatch.trim() !== '' && endBatch.trim() !== '';

    const reset = () => {
        setStartBatch('');
        setEndBatch('');
        setError(null);
    }

    const confirm = async () => {
        try {
            setWorking(true);
            setError(null);

            await onConfirm(startBatch, endBatch);
            reset();
        } catch (err) {
            if (err instanceof AxiosError) {
                setError(err.response?.data?.title ?? 'Unable to process bulk action');
            } else {
                setError('An unknown error occurred');
            }
        } finally {
            setWorking(false);
        }
    }

    return <Modal
        title="Mark Batches Ready for Upload"
        open={open}
        confirmLoading={working}
        onCancel={() => { reset(); onCancel(); }}
        onOk={confirm}
        okButtonProps={{ disabled: !valid }}
        closable={false}
        keyboard={false}
        maskClosable={false}
        destroyOnClose
    >
        <Row gutter={[32, 32]}>
            <Col span={24}>
                <Space>
                    Batches <Input
                        style={{ width: '75px' }}
                        value={startBatch}
                        onChange={e => setStartBatch(e.currentTarget.value)}
                        placeholder="Start"
                    />
                    through
                    <Input
                        style={{ width: '75px' }}
                        value={endBatch}
                        onChange={e => setEndBatch(e.currentTarget.value)}
                        placeholder="End"
                    />
                    have passed QA.
                </Space>
            </Col>
            <Col span={24}>
                The 'Do Not Upload' flag on any cards within these batches will be respected.
            </Col>
            <Col span={24}>
                {error && <Alert
                    className="bold-font"
                    style={{ marginTop: '10px' }}
                    message={error}
                    type="error"
                    showIcon
                />}
            </Col>
        </Row>
    </Modal>;
}

export default BatchesReadyForUploadModal;