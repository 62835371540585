import { useMutation } from "@tanstack/react-query";
import { StaffPackage } from "models/packages";
import { useAuthenticatedRequestCreator } from "./useRequests";

const usePackageByFriendlyId = () => {
    const get = useAuthenticatedRequestCreator<StaffPackage, number>(
        (id: number) => ({
            method: "get",
            url: `/packages/${id}`
        }), [400, 404]
    );

    const { mutateAsync: findPackage, isLoading } = useMutation(get);

    return { findPackage, isLoading };
}

export default usePackageByFriendlyId;