import { Alert, Divider, Modal, ModalProps } from "antd";
import { AxiosError } from "axios";
import { FC, MouseEvent, useState } from "react";

type ExtendedModalProps = {
    affectedCount: number;
    allowConfirm?: boolean;
}

export type BulkActionModalProps = Pick<ModalProps,
    | 'open'
    | 'title'
    | 'onOk'
    | 'onCancel'
> & ExtendedModalProps;

const BulkActionModal: FC<ModalProps & ExtendedModalProps> = ({ ...restProps }) => {
    const {
        affectedCount,
        allowConfirm = true,
        open,
        title,
        onCancel,
        onOk,
        children,
        ...remainder
    } = restProps;
    const [working, setWorking] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const confirm = async (e: MouseEvent<HTMLElement>) => {
        setError(null);
        setWorking(true);

        if (onOk) {
            try {
                await onOk(e);
            } catch (err) {
                if (err instanceof AxiosError) {
                    setError(err.response?.data?.title ?? 'Unable to process bulk action');
                } else {
                    setError('An unknown error occurred');
                }
            } finally {
                setWorking(false);
            }
        }
    }

    const cancel = (e: MouseEvent<HTMLElement>) => {
        setError(null);
        if (onCancel) {
            onCancel(e);
        }
    }

    return <Modal
        title={`Bulk Action: ${title}`}
        open={open}
        confirmLoading={working}
        onCancel={cancel}
        okButtonProps={{ disabled: !allowConfirm }}
        cancelButtonProps={{ disabled: working }}
        onOk={confirm}
        closable={false}
        keyboard={false}
        maskClosable={false}
        destroyOnClose
        {...remainder}
    >
        {children}
        <Divider />
        <Alert
            className="bold-font"
            message={`This update will affect ${affectedCount} card${affectedCount === 1 ? '' : 's'}!`}
            type="info"
            showIcon
        />
        {error && <Alert
            className="bold-font"
            style={{ marginTop: '10px' }}
            message={error}
            type="error"
            showIcon
        />}
    </Modal>;
};

export default BulkActionModal;