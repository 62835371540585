import { Select } from "antd";
import {
    CardTitleInQA,
    emptyGradeAndConditionWithStatus,
    gradedCard,
    GradedStatus, GradeOrCondition,
    IdNamePair,
    NewStatus,
    ungradedCard, UngradedStatus, UsedStatus
} from "models/cardIngestion";
import useAuctionCategories from "pages/typer/useAuctionCategories";
import { FC, KeyboardEvent, useState } from "react";
import { CancelEdit, SaveEdit } from "util/editableTable";

const toOption = ({ id, name }: IdNamePair) => ({ label: name, value: id });

const GradeOrConditionSelector: FC<{
    row: CardTitleInQA,
    save: SaveEdit<CardTitleInQA>,
    cancel: CancelEdit
}> = ({ row, save, cancel }) => {
    const { data } = useAuctionCategories();
    const isGraded = row.statusId === GradedStatus.id;
    const [status, setStatus] = useState(isGraded ? row.gradingCompanyId : row.statusId);
    const [condition, setCondition] = useState(isGraded ? row.gradeId : row.conditionId);

    const category = data!.find(c =>
        c.isLot === row.isLot &&
        c.type === row.category &&
        c.subTypes.includes(row.subCategory)
    )!;

    const excellentConditionId = category.ungradedConditions.find(c => c.name.includes('Excellent'))?.id ?? null;

    const graders = category!.graders.sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    }).map(toOption);

    const gradersAndStatuses = [
        ...(row.isLot
            ? [{ label: 'New', value: NewStatus.id }, { label: 'Used', value: UsedStatus.id }]
            : [{ label: 'Ungraded', value: UngradedStatus.id }]),
        ...graders
    ];

    const gradesOrConditions = status === UngradedStatus.id
        ? category.ungradedConditions.map(toOption)
        : category.graders.find(g => g.id === status)?.grades.map(toOption);

    const onKeyDown = async (e: KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Escape") {
            cancel();
        } else if (e.key === "Enter" && status && condition) {
            await saveChanges(condition);
        }
    };

    const saveLotStatus = async (lotStatusId: string) => {
        const s = gradersAndStatuses.find(g => g.value === lotStatusId);
        const result = emptyGradeAndConditionWithStatus({
            id: s!.value,
            name: s!.label
        });

        await save(result);
    }

    const saveChanges = async (gradeOrConditionId: string) => {
        const graderOrStatus = gradersAndStatuses.find(g => g.value === status);
        const gradeOrCondition = gradesOrConditions!.find(c => c.value === gradeOrConditionId);

        const result: GradeOrCondition = (status === UngradedStatus.id)
            ? ungradedCard(
                { id: gradeOrConditionId, name: gradeOrCondition!.label }
            )
            : gradedCard({
                gradingCompany: { id: status!, name: graderOrStatus!.label },
                grade: { id: gradeOrConditionId, name: gradeOrCondition!.label }
            });

        return await save(result);
    }

    return <>
        <Select
            onKeyDown={onKeyDown}
            options={gradersAndStatuses}
            value={status}
            onChange={async (v) => {
                if (row.isLot) {
                    await saveLotStatus(v);
                } else {
                    setStatus(v);
                    if (v !== UngradedStatus.id) {
                        setCondition(null);
                    } else {
                        setCondition(excellentConditionId);
                    }
                }
            }}
        />

        {!row.isLot && <Select
            onKeyDown={cancel}
            options={gradesOrConditions}
            value={condition}
            onChange={saveChanges}
        />}
    </>
}

export default GradeOrConditionSelector;