import { useQuery } from "@tanstack/react-query";
import { PagedResult, PageRequest } from "models/common";
import { StaffPackage } from "models/packages";
import { pageRequestToUrlSearchParams } from "util/helpers";
import { useAuthenticatedRequest } from './useRequests';

const makeAxiosRequest = (pageRequest: PageRequest) => {
    const options = pageRequestToUrlSearchParams(pageRequest);

    return {
        method: "get",
        url: `/packages/all?${options}`,
    };
};

export const useStaffPackagesCSV = (pageRequest: Omit<PageRequest, "page">) => {
    const pageRequestWithCSV = {
        ...pageRequest,
        page: 1, // ignored
        format: "csv",
    } as const;
    const axiosRequest = makeAxiosRequest(pageRequestWithCSV);

    return useAuthenticatedRequest<string>(axiosRequest);
};

const useStaffPackages = (
    pageRequest: PageRequest
) => {
    const key = ["staff-packages", pageRequest];

    const getPackages = useAuthenticatedRequest<PagedResult<StaffPackage>>(
        makeAxiosRequest(pageRequest)
    );

    return useQuery(key, getPackages);
};

export default useStaffPackages;
