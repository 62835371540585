import { DownOutlined, ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, Input, Menu, Row, Space } from "antd";
import { FC, KeyboardEvent, useState } from "react";
import BatchesReadyForUploadModal from "./BatchesReadyForUploadModal";
import CardsReadyForUploadModal from "./CardsReadyForUploadModal";
import ChangePackageIdModal from "./ChangePackageIdModal";
import DoNotUploadModal from "./DoNotUploadModal";

const ChangePackageIdAction = 0;
const DoNotUploadAction = 1;
const CardsReadyToUploadAction = 2;

interface CardQAToolbarProps {
    onSearch: (term: string) => void;
    onSearchClear?: () => void;
    selectedRowCount: number;
    searchFailed: boolean;
    onBulkDoNotUpload?: () => Promise<void>;
    onBulkPackageIdUpdate?: (newPackageId: string) => Promise<void>;
    onBatchesPassedQA?: (startBatch: string, endBatch: string) => Promise<void>;
    onCardsPassedQA?: () => Promise<void>;
}

const CardQAToolbar: FC<CardQAToolbarProps> = ({
    onSearch,
    onSearchClear,
    selectedRowCount,
    searchFailed,
    onBulkDoNotUpload,
    onBulkPackageIdUpdate,
    onBatchesPassedQA,
    onCardsPassedQA
}) => {
    const [packageIdModalOpen, setPackageIdModalOpen] = useState(false);
    const [doNotUploadModalOpen, setDoNotUploadModalOpen] = useState(false);
    const [batchesReadyForUploadModalOpen, setBatchesReadyForUploadModalOpen] = useState(false);
    const [cardsReadyForUploadModalOpen, setCardsReadyForUploadModalOpen] = useState(false);
    const noRowsSelected = selectedRowCount === 0;

    const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter" && e.currentTarget.value.trim() !== '') {
            onSearch(e.currentTarget.value);
        }
    }

    return <Row align="middle">
        <Col span={3}>
            <Dropdown.Button
                icon={<DownOutlined />}
                type={noRowsSelected ? 'default' : 'primary'}
                overlay={<Menu items={[
                    {
                        label: 'Change Package ID',
                        key: ChangePackageIdAction,
                        disabled: noRowsSelected,
                        onClick: () => setPackageIdModalOpen(true)
                    },
                    {
                        label: 'Do Not Upload',
                        key: DoNotUploadAction,
                        disabled: noRowsSelected,
                        onClick: () => setDoNotUploadModalOpen(true)
                    },
                    {
                        label: 'Ready for Upload',
                        key: CardsReadyToUploadAction,
                        disabled: noRowsSelected,
                        onClick: () => setCardsReadyForUploadModalOpen(true)
                    }
                ]} />}
            >
                {noRowsSelected ? 'No' : selectedRowCount} Row{selectedRowCount !== 1 ? 's' : ''} Selected
            </Dropdown.Button>
        </Col>

        <Col span={4}>
            <Space>
                <div>Jump To:</div>
                <Input
                    onKeyDown={onKeyDown}
                    placeholder="Batch Num or Pkg ID"
                    prefix={searchFailed ? <ExclamationCircleOutlined title="No Results!" /> : <span />}
                    status={searchFailed ? 'error' : ''}
                    allowClear
                    onChange={e => {
                        if (e.currentTarget.value === '' && onSearchClear) {
                            onSearchClear();
                        }
                    }}
                />
            </Space>
        </Col>

        <Col span={4}>
            <Button type="link" onClick={() => setBatchesReadyForUploadModalOpen(true)} icon={<UploadOutlined />}>
                Ready for Upload ...
            </Button>
        </Col>

        <Col span={24}>
            <ChangePackageIdModal
                open={packageIdModalOpen}
                affectedCount={selectedRowCount}
                onCancel={() => setPackageIdModalOpen(false)}
                onConfirm={async (newPackageId: string) => {
                    if (onBulkPackageIdUpdate) {
                        await onBulkPackageIdUpdate(newPackageId)
                    }
                    setPackageIdModalOpen(false)
                }}
            />

            <DoNotUploadModal
                open={doNotUploadModalOpen}
                affectedCount={selectedRowCount}
                onCancel={() => setDoNotUploadModalOpen(false)}
                onConfirm={async () => {
                    if (onBulkDoNotUpload) {
                        await onBulkDoNotUpload()
                    }
                    setDoNotUploadModalOpen(false)
                }}
            />

            <BatchesReadyForUploadModal
                open={batchesReadyForUploadModalOpen}
                onCancel={() => setBatchesReadyForUploadModalOpen(false)}
                onConfirm={async (startBatch: string, endBatch: string) => {
                    if (onBatchesPassedQA) {
                        await onBatchesPassedQA(startBatch, endBatch)
                    }
                    setBatchesReadyForUploadModalOpen(false)
                }}
            />

            <CardsReadyForUploadModal
                open={cardsReadyForUploadModalOpen}
                affectedCount={selectedRowCount}
                onCancel={() => setCardsReadyForUploadModalOpen(false)}
                onConfirm={async () => {
                    if (onCardsPassedQA) {
                        await onCardsPassedQA();
                    }
                    setCardsReadyForUploadModalOpen(false)
                }}
            />
        </Col>
    </Row>;
};

export default CardQAToolbar;