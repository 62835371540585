import { Box, Flex, VStack } from "@chakra-ui/react";
import { Badge, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import Currency from "components/Currency";
import TableActions from "components/TableActions";
import AddUserModal from "components/staff/Users/AddUserModal";
import UpdateUserModal from "components/staff/Users/UpdateUserModal";
import useUsers, {
    StaffUsersProfile,
    UserAddress,
    useUsersCSV,
} from "hooks/useUsers";
import { InitialPageRequest, PageRequest, PageSizeOptions } from "models/common";
import { FC, useState } from "react";
import downloadAsCSV from "util/downloadAsCSV";
import { roleNameToDisplayName } from "util/helpers";
import { createCustomFilter } from "util/table";
import RolesFilter from "../../components/staff/Users/RolesFilter";

import { CrownTwoTone } from "@ant-design/icons";
import '../../styles/staff/users.css';

const AddressBox: FC<{ address: UserAddress }> = ({ address }) => (
    <VStack align="start">
        <Box>{address.addressLine1}</Box>
        <Box>{address.addressLine2}</Box>
        <Box>
            {address.city}, {address.state} {address.zipCode}
        </Box>
    </VStack>
);

const UsersPage = () => {
    const [roleFilter, setRoleFilter] = useState<string | undefined>();

    const [{ page, filter, filterBy, withinDays, sort, pageSize }, setPageRequest] =
        useState<PageRequest>(InitialPageRequest);

    const { isLoading, data, refetch } = useUsers({
        page,
        withinDays,
        sort,
        filter,
        filterBy,
        pageSize
    });

    const { data: records, totalRecords } = data || {};

    const filterChanged = (value: string) => {
        setPageRequest({ page: 1, filter: value, filterBy, withinDays, sort, pageSize });
    };

    const daysChanged = (value: number) => {
        setPageRequest({ page: 1, filter, filterBy, withinDays: value, sort, pageSize });
    };

    const tableChanged = (
        { current: newPage, pageSize: newPageSize }: any, // pagination
        filters: Record<string, FilterValue | null>, // filtering
        { column, field, order }: any
    ) => {
        setPageRequest({
            page: newPageSize !== pageSize ? 1 : newPage,
            pageSize: newPageSize,
            filter,
            filterBy: filters,
            withinDays,
            sort: column ? [field, order === "ascend"] : null,
        });
    };

    const fetchCSV = useUsersCSV({
        filter,
        filterBy,
        withinDays,
        sort,
    });

    const handleCSVExport = async () => {
        const csvData = await fetchCSV();
        const date = new Date().toISOString().split("T")[0];
        const filename = `all-users-${date}.csv`;
        downloadAsCSV(csvData, filename);
    };

    // username/name/email/mailing address with out of the box filter/sort.
    const columns: ColumnsType<StaffUsersProfile> = [
        {
            title: "Created",
            dataIndex: "created",
            key: "created",
            sorter: true,
            render: (created) => new Date(created).toLocaleDateString(),
        },
        {
            title: "Username",
            dataIndex: "userName",
            key: "username",
            sorter: true,
            render: (t, rec) => <>
                <Badge dot
                    text={t}
                    color={rec.active ? 'green' : 'red'}
                />
                {rec.vip && <CrownTwoTone style={{ marginLeft: '5px' }} twoToneColor='#faad14' />}
            </>,
        },
        {
            title: "First Name",
            dataIndex: "firstName",
            key: "firstName",
            sorter: true,
            render: (t) => <Box>{t}</Box>,
        },
        {
            title: "Last Name",
            dataIndex: "lastName",
            key: "lastName",
            sorter: true,
            render: (t) => <Box>{t}</Box>,
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: true,
            render: (t) => <Box>{t}</Box>,
        },
        {
            title: "Mailing Address",
            dataIndex: "address",
            key: "address",
            render: (a: UserAddress) => a && <AddressBox address={a} />,
        },
        {
            title: "Roles",
            dataIndex: "roles",
            key: "roles",
            sorter: false,
            ...createCustomFilter(
                RolesFilter,
                setRoleFilter,
                roleFilter
            ),
            render: (r: string[]) => r.map(x => <Box key={x}>{roleNameToDisplayName(x)}</Box>)
        },
        {
            title: "Balance",
            dataIndex: "balanceInCents",
            sorter: true,
            render: c => <Currency cents={c} showZero style={(c === 0 ? { color: 'black' } : {})} />
        },
        {
            title: "Recent Pkgs.",
            dataIndex: "recentPackageCount",
            sorter: true
        },
        {
            title: "Recent Sales",
            dataIndex: "recentSalesInCents",
            sorter: true,
            render: c => <Currency cents={c} showZero style={{ color: 'black' }} />
        },
        {
            title: "",
            key: "edit",
            render: (_, rec) => (
                <UpdateUserModal user={rec} onSuccess={async () => { await refetch(); }} />
            ),
        },
    ];

    return (
        <VStack w="100%" spacing={7} minHeight="800px" p="5rem">
            <Flex w="100%" justifyContent="space-between" alignItems="end">
                <Box h="100%" fontSize={36} textAlign="center">
                    Users
                </Box>
                <Flex pb={2} alignItems="end" flexDir="column">
                    <TableActions
                        onDaysChanged={daysChanged}
                        onFilterChanged={filterChanged}
                        isLoading={isLoading}
                        filterPlaceholder="Search Users"
                        onExportCSV={handleCSVExport}
                    ></TableActions>
                </Flex>
            </Flex>
            <Box w="100%">
                <AddUserModal />
                <Table
                    columns={columns}
                    dataSource={records}
                    rowKey={(r) => r.id}
                    onChange={tableChanged}
                    pagination={{
                        total: totalRecords,
                        current: page,
                        pageSize,
                        pageSizeOptions: PageSizeOptions,
                        showSizeChanger: true,
                        showQuickJumper: true,
                    }}
                    loading={isLoading}
                />
            </Box>
        </VStack>
    );
};

export default UsersPage;
