import { FC } from "react";
import BulkActionModal, { BulkActionModalProps } from "./BulkActionModal";

const DoNotUploadModal: FC<BulkActionModalProps & {
    onConfirm: () => Promise<void>
}> = ({ affectedCount, open, onCancel, onConfirm }) => {
    const confirm = async () => {
        await onConfirm();
    }

    return <BulkActionModal
        title="Do Not Upload"
        affectedCount={affectedCount}
        open={open}
        onOk={confirm}
        onCancel={onCancel}
    >
        <div>All selected cards will be flagged and will not be uploaded to eBay.</div>
    </BulkActionModal>
};

export default DoNotUploadModal;