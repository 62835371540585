import { useMutation } from "@tanstack/react-query";
import { ApplicationUser } from "models/applicationUsers";
import { PackageStatus } from "models/packages";
import { useAuthenticatedRequestCreator } from "./useRequests";

export interface PackageUpdate {
    id: string;
    customer: ApplicationUser;
    packageTypeId: string;
    packageStatus: PackageStatus;
    auctionEventId?: string;
    priority: boolean;
    processingFeeInCents: number | null;
}

const usePackageUpdate = () => {
    const update = useAuthenticatedRequestCreator<boolean, PackageUpdate>(model => ({
        method: "post",
        url: "/packages/update",
        data: {
            id: model.id,
            customerId: model.customer.id,
            packageTypeId: model.packageTypeId,
            packageStatus: model.packageStatus,
            auctionEventId: model.auctionEventId,
            priority: model.priority,
            processingFeeInCents: model.processingFeeInCents
        }
    }));

    const { mutateAsync: updatePackage } = useMutation(
        (model: PackageUpdate) => update(model)
    );

    return { updatePackage };
}

export default usePackageUpdate;